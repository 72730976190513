import { reduxConstant } from "../../constants/appconstant"

const intialState = {
    isLoading: false,
    dashboard: null,
    linkFetched: false,
    list: [],
    pagination: {}
}

export const notificationReducer = (state = intialState, action) => {

    switch (action.type) {
        //send notification
        case reduxConstant.SEND_NOTIFICATION_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case reduxConstant.SEND_NOTIFICATION_FAIL:
            return {
                ...state,
                isLoading: false,
            };


        //notification list
        case reduxConstant.NOTIFICATION_LIST_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.NOTIFICATION_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: action.list,
                pagination: action.pagination
            };
        case reduxConstant.NOTIFICATION_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        //notification delete

        case reduxConstant.NOTIFICATION_DELETE_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.NOTIFICATION_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                list: state.list.filter(e => String(e._id) !== String(action.id)),
            };
        case reduxConstant.NOTIFICATION_DELETE_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        //notification edit

        case reduxConstant.NOTIFICATION_EDIT_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.NOTIFICATION_EDIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case reduxConstant.NOTIFICATION_EDIT_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return { ...state }

    }



}