import React, { useEffect, useState } from "react"
import { DatePicker, Space } from 'antd';
import { Link } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import Textfiled from "../../common";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addTournament } from "../../redux/Action/Auth";
import moment from 'moment';

const AddTournamentDetail = () => {

    const dispatch = useDispatch()
const [isDisabled,setDisabled]= useState(false)
    const loading = useSelector((state) => state.tournamentReducer.loading);

    useEffect(() => {
        document.title = 'Albastini';
        window.scrollTo(0, 0)
    }, [])

    const formik = useFormik({
        initialValues: {
            name: "",
            registerCoins: "",
            totalPlayers: "",
            dateTime: ""
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required("Please enter name.")
                .test("trim", "Please enter valid title.",
                    (value) => {
                        value = value?.replace(/\s+/g, "")
                        return ! /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)
                    })
                .min(3, "Name must contain at least 3 characters.")
                .max(255),
            registerCoins: Yup.string()
                .required("Please enter amount.")
                .test("trim", "Please enter valid amount.",
                    (value) => {
                        value = value?.replace(/\s+/g, "")
                        return ! /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)
                    }),
            totalPlayers: Yup.number().required("Please enter total number of players.")
                .moreThan(7, "Player count can only be multiples of 8.")
                .lessThan(1025, "Total players should be equal or less than 1024.")
                .test("module", "Player count can only be multiples of 8.", function (value) {

                    if (value) {

                        value = parseInt(value)

                        return value % 8 === 0
                    }

                    return false
                }),

            dateTime: Yup.string().required("Please enter start date.")
        }),

        onSubmit: (values) => {
            if (values.dateTime < moment()) formik.setFieldError("dateTime", "Start Date & Time should be greater than current time.")
            else {
                formik.setErrors({})
                setDisabled(true)
                setTimeout(() => {
                dispatch(addTournament(values))
                setDisabled(false)  
                }, 1500);
            }

        }
    })

    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/tournamentmanagement"> {appconstant.tournamentManagement} </Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.createTournamentDetail}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <form className="content-e" onSubmit={formik.handleSubmit}>
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.createTournamentDetail}</h6>
                        <input type="text" className="search-bar" placeholder="Search" style={{ visibility: "hidden" }} />

                    </div>
                    <div className="wrapper_line">

                        <div className="form-group">
                            <label>{appconstant.tournamentName}</label>
                            <Textfiled

                                className='form-control'
                                placeholder='Tournament Name'
                                name="name"
                                value={formik.values.name}
                                onChange={(e)=>{
                                    if (e.target.value.startsWith(' ')) e.preventDefault()
                                    else formik.handleChange(e) 
                                    }}
                                maxLength={15}
                            />


                        </div>
                        {formik.errors.name && <div className="form-validation">{formik.errors.name}</div>}


                        <div className='form-group'>
                            <label>{appconstant.tournamentDateTime}</label>
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    placeholder="Select Date & Time"
                                    showTime={{ format: "HH:mm" }} 
                                    format="DD/MM/YYYY HH:mm"
                                    clearIcon={null}
                                    needConfirm={false}
                                    name="dateTime"
                                    value={formik.values.dateTime}
                                    onChange={e => {
                                        formik.setFieldValue("dateTime", e)
                                    }}
                                    disabledDate={(current) => {
                                        return moment().startOf("day") >= current
                                    }}

                                    disabledTime={(aa) => {

                                        const date = moment().format("HH:mm")
                                        const hour = parseInt(date.split(":")[0])
                                        const minute = parseInt(date.split(":")[1])

                                        if (!aa || parseInt(aa.format("DD")) === parseInt(moment().format("DD"))) {

                                            if (minute < 58) {

                                                return {
                                                    disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].filter(x => x < hour),
                                                    disabledMinutes: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59].filter(x => x < (minute + 2))
                                                }
                                            }
                                            else {

                                                return {
                                                    disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].filter(x => x < (hour + 1)),
                                                    disabledMinutes: () => []
                                                }
                                            }
                                        }
                                    }}

                                />
                            </Space>
                        </div>

                        {formik.errors.dateTime && <div className="form-validation">{formik.errors.dateTime}</div>}

                        <div className="form-group">
                            <label>{appconstant.totalNumberOfPlayers}</label>
                            <Textfiled

                                className='form-control'
                                placeholder='Total Number Of Players'
                                name="totalPlayers"
                                value={formik.values.totalPlayers}
                                onChange={(e) => {

                                    if (/^\d+$/.test(e.target.value) || !e.target.value) formik.handleChange(e)
                                    else e.preventDefault()
                                }}
                                maxLength={4}

                            />
                        </div>
                        {formik.errors.totalPlayers && <div className="form-validation">{formik.errors.totalPlayers}</div>}


                        <div className="form-group">
                            <label>{appconstant.registerationAmount}</label>
                            <Textfiled

                                className='form-control'
                                placeholder={appconstant.registerationAmount}
                                name="registerCoins"
                                value={formik.values.registerCoins}
                                onChange={(e) => {

                                    if (/^\d+$/.test(e.target.value) || !e.target.value) formik.handleChange(e)
                                    else e.preventDefault()
                                }}
                                maxLength={5}
                            />
                        </div>
                        {formik.errors.registerCoins && <div className="form-validation">{formik.errors.registerCoins}</div>}

                        <div className="center-align">
                            <button disabled={isDisabled} type="submit" className="button-list">{appconstant.createbtn}</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>

    )
}
export default AddTournamentDetail;
