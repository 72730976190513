import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { tournamentDetails } from "../../redux/Action/Auth";
import moment from 'moment'

const ViewTournament = () => {

    const id = useLocation().state.id
    const dispatch = useDispatch()

    const [currentPage, setCurrentPage] = useState(1)
    const loading = useSelector(x => x.tournamentReducer.isLoading)
    const details = useSelector(x => x.tournamentReducer.tournamentDetail)

    // handle pagination and sorting
    const handleTableChange = (paginationChange, x, sortChange) => {

        setCurrentPage(paginationChange.current)
        let offset = (paginationChange.current - 1) * 10
        let limit = 10
        let sort = sortChange?.columnKey || null
        let order = sortChange?.order === "ascend" ? 1 : sortChange?.order === "descend" ? -1 : null

        dispatch(tournamentDetails({ tournament: id, offset, limit, order, sort }))
    }


    useEffect(() => {

        dispatch(tournamentDetails({ offset: 0, limit: 0, tournament: id }))
        document.title = 'Albastini';
        window.scrollTo(0, 0)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const columns = [{
        title: 'Sr. No.',
        dataIndex: 'srno',
        key: 'srno',
        sorter: false,
        render: (value, item, index) => (index + 1)
    },


    {
        title: 'User Name',
        dataIndex: 'userName',
        key: 'userName',
        sorter: true,
    },
    {
        title: 'Full Name ',
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: true,
    },


    {
        title: 'Email Address ',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
    },






    ];


    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/tournamentmanagement"> {appconstant.tournamentManagement} </Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.viewTournamentDetails}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.viewTournamentDetails}</h6>
                        <input type="text" className="search-bar" placeholder="Search" style={{ visibility: "hidden" }} />

                    </div>
                    <div className="wrapper_line">

                        <div className="fields">
                            <label>{appconstant.tournamentName}</label>
                            <p>{details?.detail?.name || "N/A"}</p>
                        </div>
                        <div className="fields">
                            <label>{appconstant.tournamentDateTime}</label>
                            <p>{details?.detail?.dateTime ? moment(details?.detail?.dateTime).format("DD/MM/YYYY HH:mm:ss") : "N/A"}</p>
                        </div>

                        < div className="fields">
                            <label>{appconstant.totalNumberOfPlayers}</label>
                            <p>{details?.detail?.totalPlayers || "N/A"}</p>
                        </div>


                        < div className="fields">
                            <label>{appconstant.registerationAmount}</label>
                            <p>{details?.detail?.registerCoins}</p>
                        </div>

                        < div className="fields">
                            <label>{appconstant.winer}</label>
                            <p>{details?.detail?.winner || "N/A"}</p>
                        </div>

                    </div>
                </div>

                <div className="content-e table-space-top">

                    <Table

                        dataSource={details?.detail?.players?.map((item, index) => ({ ...item, key: index }))}
                        columns={columns}
                        showSorterTooltip={false}
                        loading={loading}
                        pagination={{ total: details?.paginationData?.totalCount || 0, current: currentPage }}
                        onChange={handleTableChange}
                    />
                </div>

            </div>
        </div>

    )
}
export default ViewTournament;
