import React, { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from 'antd';
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { tournamentList, tournamentBlock } from "../../redux/Action/Auth";
import moment from 'moment';

const TournamentManagement = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    let [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [disableModelVisible, setDisableModelVisible] = useState(null)
    const tournamentListData = useSelector(x => x.tournamentReducer.tournamentList)
    const loading = useSelector((state) => state.tournamentReducer.loading);


    const disableModel = (record) => {

        const modal1 = Modal.confirm({
            title: record?.isBlock ? appconstant.unBlock : appconstant.block,
            content: record?.isBlock
                ? appconstant.unblockWarning
                : appconstant.blockWarning,
            okText: 'Yes',
            centered: true,
            cancelText: 'No',
            onOk() {
                dispatch(tournamentBlock({ id: record._id }))
            },
            onCancel() {

            },
            closable: true,
            visible: { disableModelVisible },
            maskClosable: true,
            className: "new-button"
        });
        setDisableModelVisible(modal1)

    };

    const columns = [{
        title: 'Sr. No.',
        dataIndex: 'srno',
        key: 'srno',
        sorter: false,
        render: (value, item, index) => (currentPage ? currentPage - 1 : 0) * 10 + (index + 1)
    },

    {
        title: 'Tournament Name',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
    },

    {
        title: 'Tournament Start Date & Time',
        dataIndex: 'dateTime',
        key: 'dateTime',
        sorter: true,
        render: (value, item, index) => (<div>{item.dateTime ? moment(item.dateTime).format("DD/MM/YYYY HH:mm:ss") : "N/A"}</div>)
    },

    {
        title: 'Total Number Of Players',
        dataIndex: 'totalPlayers',
        key: 'totalPlayers',
        sorter: true,
    },

    {
        title: 'Registration Amount',
        dataIndex: 'registerCoins',
        key: 'registerCoins',
        sorter: true,
    },
    {
        title: 'Actions',
        dataIndex: 'Posted',
        key: 'Posted',
        render: (value, item, index) => {
            return (

                <div>
                    {/* <Link to="/viewtournament"> */}
                    <button className="view-btn" type="submit" onClick={() => navigate('/viewtournament', { state: { id: item?._id } })}>{appconstant.view}</button>
                    {/* </Link> */}

                    <button className={item?.isStart ? "edit-btn cursor-not-allowed" : "edit-btn"} disabled={item?.isStart} type="submit" onClick={() => navigate('/edittournamentdetail', { state: { ...item, id: item?._id } })} >{appconstant.edit}</button>

                    <button className={item?.isStart ? "block-btn cursor-not-allowed" : "block-btn"} disabled={item?.isStart} type="submit"
                        onClick={() => {
                            disableModel(item)
                        }} >
                        {!item.isBlock ? appconstant.block : appconstant.unBlock}
                    </button>
                    <Link to="/tournamentlogs" state={item?._id}>
              <button className="tour-btn" type="submit">
               Tournament Logs
              </button>
            </Link>

                </div>
            )
        }
    },
    ];

    // handle pagination and sorting
    const handleTableChange = (paginationChange, x, sortChange) => {

        setCurrentPage(paginationChange.current)
        let offset = (paginationChange.current - 1) * 10
        let limit = 10
        let sort = sortChange?.columnKey || null
        let order = sortChange?.order === "ascend" ? 1 : sortChange?.order === "descend" ? -1 : null

        dispatch(tournamentList({ search, offset, limit, order, sortBy: sort }))
    }

    useEffect(() => {

        dispatch(tournamentList({}))
        document.title = 'Albastini';

    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.tournamentManagement}</Breadcrumb.Item>
            </Breadcrumb>


            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">


                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.tournamentManagement}</h6>
                        <input
                            type="text"
                            className="iocn-search  search-bar"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                                const value = (e.target.value).trimStart()
                                setSearch(value)
                                setCurrentPage(1)
                                dispatch(tournamentList({ search: value, offset: 0, limit: 10 }))
                            }}
                        />
                    </div>

                    <div >
                        <Link to="/addtournamentDetail"><button type="submit" className="button-list ">{appconstant.create}</button>
                        </Link>
                    </div>


                    <Table
                        dataSource={tournamentListData?.list?.map((item, index) => ({ ...item, key: index }))}
                        columns={columns}
                        showSorterTooltip={false}
                        pagination={{ total: tournamentListData?.pagination?.totalCount || 0, current: currentPage,showSizeChanger:false }}
                        onChange={handleTableChange}
                        loading={loading}
                        
                        
                    />

                </div>
            </div>


        </div>

    )
}
export default TournamentManagement
