import { reduxConstant } from "../../constants/appconstant";

export const loginAction = (data) => ({ type: reduxConstant.LOGIN_LOAD, data });

export const Forgot = (data) => ({ type: reduxConstant.FORGOT_LOAD, data });

export const dashboard = () => ({ type: reduxConstant.DASHBOARD_LOAD });

export const logout = () => ({ type: reduxConstant.LOGOUT_LOAD });

export const changePassword = (data) => ({
  type: reduxConstant.CHANGE_PASSWORD_LOAD,
  data,
});

export const forgotLinkValid = (data) => ({
  type: reduxConstant.FORGOT_LINK_LOAD,
  data,
});

export const resetPassword = (data) => ({
  type: reduxConstant.RESET_PASSWORD_LOAD,
  data,
});

export const sendNotification = (data) => ({
  type: reduxConstant.SEND_NOTIFICATION_LOAD,
  data,
});

export const userListAction = (data) => ({
  type: reduxConstant.API_User_List_LOAD,
  data,
});

export const blockUserAction = (data) => ({
  type: reduxConstant.API_BLOCK_USER_LOAD,
  data,
});

export const viewUserAction = (data) => ({
  type: reduxConstant.API_VIEW_USER_LOAD,
  data,
});

export const editUserAction = (data, navigate) => ({
  type: reduxConstant.API_EDIT_USER_LOAD,
  data,
  navigate,
});

export const shopListAction = (data) => ({
  type: reduxConstant.API_SHOP_LIST_LOAD,
  data,
});

export const viewShopAction = (data) => ({
  type: reduxConstant.API_VIEW_SHOP_LOAD,
  data,
});

export const addShopItemAction = (data) => ({
  type: reduxConstant.API_ADD_SHOP_ITEM_LOAD,
  data,
});

export const versionDetailAction = () => ({
  type: reduxConstant.API_VERSION_DETAILS_LOAD,
});

export const versionUpadteAction = (data) => ({
  type: reduxConstant.UPDATE_VERSION_LOAD,
  data
});

export const notificationList = (data) => ({
  type: reduxConstant.NOTIFICATION_LIST_LOAD,
  data,
});

export const notificationDelete = (data) => ({
  type: reduxConstant.NOTIFICATION_DELETE_LOAD,
  data,
});

export const notificationEdit = (data, navigate) => ({
  type: reduxConstant.NOTIFICATION_EDIT_LOAD,
  data,
  navigate,
});

export const addTournament = (data, navigate) => ({
  type: reduxConstant.TOURNAMENT_ADD_LOAD,
  data,
  navigate,
});

export const tournamentList = (data) => ({
  type: reduxConstant.TOURNAMENT_LIST_LOAD,
  data,
});

export const tournamentDetails = (data) => ({
  type: reduxConstant.TOURNAMENT_DETAILS_LOAD,
  data,
});

export const tournamentBlock = (data) => ({
  type: reduxConstant.TOURNAMENT_BLOCK_LOAD,
  data,
});

export const tournamentEdit = (data, navigate) => ({
  type: reduxConstant.TOURNAMENT_EDIT_LOAD,
  data,
  navigate,
});

export const addShopDataAction = (data, naviagte) => ({
  type: reduxConstant.ADD_SHOP_DATA_LOAD,
  data,
  naviagte,
});

export const getShopDataAction = (data, naviagte) => ({
  type: reduxConstant.GET_SHOP_DATA_LOAD,
  data,
  naviagte,
});
export const userAddCoinAction = (data, naviagte) => ({
  type: reduxConstant.API_USER_ADD_COIN_LOAD,
  data,
  naviagte,
});

export const getGameListAction = (data, naviagte) => ({
  type: reduxConstant.API_GET_GAME_LIST_LOAD,
  data,
  naviagte,
});

export const shopEditAction = (data, naviagte) => ({
  type: reduxConstant.API_SHOP_EDIT_LOAD,
  data,
  naviagte,
});


export const updateAppVersion = (data)=>({
  type: reduxConstant.API_UPDATE_VERSION_LOAD,
  data
});

export const economylogAction = (data)=>({
  type: reduxConstant.ECONOMY_LOG_LOAD,
  data
});

export const tournamentlogsAction = (data) => ({
  type : reduxConstant.TOURNAMENT_LOG_LOAD,
  data 
})

export const gamesLogsAction = (data) => ({
  type : reduxConstant.GAME_LOGS_LOAD,
  data 
})







