import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Changepassword from "../components/changepassword";
import Dashboard from "../components/dashboard";
import ForgotPassword from "../components/forgotpassword";
import Login from "../components/login";
import MainLayout from "../layout/mainlayout";
import UserManagement from "../components/usermanagement";
import TournamentManagement from "../components/TournamentManagement";
import UsesrDetail from "../components/usermanagement/viewUser";
import EditUserDetail from "../components/usermanagement/editUserDetail";
import ViewTournament from "../components/TournamentManagement/viewTournamentDetails";
import EditTournamentDetail from "../components/TournamentManagement/editTournamentDetails";
import AddTournamentDetail from "../components/TournamentManagement/addTournamentManagement";
import PrivateRoute, { PublicRoute } from "./privateRoute";
import ResetPassword from "../components/resetpassword";
import NotificationManagement from "../components/Notification Management";
import AddNotification from "../components/Notification Management/addNotifcation";
import EditNotification from "../components/Notification Management/editNotification";
import ShopManagement from "../components/shopManagement";
import ShopDetail from "../components/shopManagement/viewShop";
import ShopManagementScreen from "../components/shopMangement_screen";
import CreateTheme from "../components/shopMangement_screen/createTheme";
import CreateCard from "../components/shopMangement_screen/createCard";
import CreateEmoji from "../components/shopMangement_screen/createEmoji";
import EditEmoji from "../components/shopMangement_screen/editEmoji";
import EditTheme from "../components/shopMangement_screen/editTheme";
import EditCard from "../components/shopMangement_screen/editCard";
import Terms from "../components/Terms";
import Privacy from "../components/Privacy";
import Delete from "../components/Delete";
import TournamentRules from "../components/TournamentRules";
import HowToPlay from "../components/HowToPlay";
import VersionManagement from "../components/versionManagement";
import EconomyLogs from "../components/usermanagement/economyLogs";
import TournamentLogs from "../components/TournamentManagement/tournamentlogs";
import Gamelog from "../components/shopManagement/gameLog";

const RoutesNew = () => {
  return (
    <div>
      {/* <Router> */}
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={!localStorage.token ? "login" : "dashboard"} />
          }
        />
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/terms"
          element={
            <PublicRoute>
              <Terms />
            </PublicRoute>
          }
        />

        <Route
          path="/privacy"
          element={
            <PublicRoute>
              <Privacy />
            </PublicRoute>
          }
        />
        <Route
          path="/delete"
          element={
            <PublicRoute>
              <Delete />
            </PublicRoute>
          }
        />
        <Route
          path="/tournamentRules"
          element={
            <PublicRoute>
              <TournamentRules />
            </PublicRoute>
          }
        />
        <Route
          path="/howToPlay"
          element={
            <PublicRoute>
              <HowToPlay />
            </PublicRoute>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/resetpassword"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route path="/" element={<MainLayout />}>
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/changepassword"
            element={
              <PrivateRoute>
                <Changepassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/usermanagement"
            element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            }
          />

          <Route
            path="/economylogs"
            element={
              <PrivateRoute>
                <EconomyLogs />
              </PrivateRoute>
            }
          />
          <Route
            path="/tournamentmanagement"
            element={
              <PrivateRoute>
                <TournamentManagement />
              </PrivateRoute>
            }
          />

          <Route
            path="/tournamentlogs"
            element={
              <PrivateRoute>
                <TournamentLogs />
              </PrivateRoute>
            }
          />

          <Route
            path="/usesrdetail"
            element={
              <PrivateRoute>
                <UsesrDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/edituserdetail"
            element={
              <PrivateRoute>
                <EditUserDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/viewtournament"
            element={
              <PrivateRoute>
                <ViewTournament />
              </PrivateRoute>
            }
          />
          <Route
            path="/edittournamentdetail"
            element={
              <PrivateRoute>
                <EditTournamentDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/addtournamentDetail"
            element={
              <PrivateRoute>
                <AddTournamentDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/notificationmanagement"
            element={
              <PrivateRoute>
                <NotificationManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/addnotification"
            element={
              <PrivateRoute>
                <AddNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/editnotification"
            element={
              <PrivateRoute>
                <EditNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/gamemanagement"
            element={
              <PrivateRoute>
                <ShopManagement />
              </PrivateRoute>
            }
          />

          <Route
            path="/gamelog"
            element={
              <PrivateRoute>
                <Gamelog />
              </PrivateRoute>
            }
          />
          <Route
            path="/shopmanagement"
            element={
              <PrivateRoute>
                <ShopManagementScreen />
              </PrivateRoute>
            }
          />
          <Route
            path="/createtheme"
            element={
              <PrivateRoute>
                <CreateTheme />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/createcard"
            element={
              <PrivateRoute>
                <CreateCard />
              </PrivateRoute>
            }
          ></Route>

          <Route
            path="/createemoji"
            element={
              <PrivateRoute>
                <CreateEmoji />
              </PrivateRoute>
            }
          ></Route>
          <Route
            path="/editEmoji"
            element={
              <PrivateRoute>
                <EditEmoji />
              </PrivateRoute>
            }
          />
          <Route
            path="/editTheme"
            element={
              <PrivateRoute>
                <EditTheme />
              </PrivateRoute>
            }
          />
          <Route
            path="/editCard"
            element={
              <PrivateRoute>
                <EditCard />
              </PrivateRoute>
            }
          />
          <Route
            path="/shopDetail"
            element={
              <PrivateRoute>
                <ShopDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/versionmanagement"
            element={
              <PrivateRoute>
                <VersionManagement />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
      {/* </Router> */}
    </div>
  );
};
export default RoutesNew;
