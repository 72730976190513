const appconstant = {
  coinBalance :'Coin Balance',
  changepassword: "Change Password",
  Oldpassword: "Old Password",
  NewPassword: "New Password",
  ConfirmNewPassword: "Confirm New Password",
  contactus: "Contact us",
  home: "Dashboard",
  EmailAddress: "Email Address",
  eventManagement: "Event Management",
  UserManagement: "User Management",
  ShopManagement: "Shop Management",
  VersionManagement: "Version Management",
  GameManagement: "Game Management",
  ThemeMangement: "Theme Mangement",
  CardManagement: "Card Background",
  numberOfCoins: "Number of Coins",
  Emoji: "Emoji",
  Password: "Password",
  ConfrmPassword: "Confrm Password",
  email: "Email",
  contactNo: "Contact No",
  gender: "Gender",
  unBlock: "Unblock",
  age: "Age",
  name: "User Name",
  iteamName: "Name",
  view: "View",
  itemName: "Item Name",
  edit: "Edit",
  type: "Type",
  block: "Block",
  clickToUpload: "Click to upload",
  userReportManagement: "User Report Management",
  tournamentManagement: "Tournament Management",
  viewUser: "View User Details",
  viewShop: "View Shop Details",
  userName: "User Name",
  addShopItem: "Add Item",
  John: "John",
  cost: "Cost",
  fullName: "Full Name",
  JohnDee: "John Dee",
  id: "	john@yopmail.com",
  phone: "Phone Number",
  number: "9856471254",
  editUser: "Edit User Details",
  editTheme: "Edit Theme",
  editCard: "Edit Card",
  editEmoji: "Edit Emoji",
  androidAppVersion: "Android App Version",
  iosAppVersion: "iOS App Version",
  viewTournamentDetails: "View Tournament Details",
  tournamentName: "Tournament Name",
  ThemeName: "Theme Name(English)",
  ThemeNameSwahili: "Theme Name (Swahili)",
  EmojiName: "Emoji Name(English)",
  EmojiNameSwahili: "Emoji Name (Swahili)",
  CardBackgroundName: "Card Background Name (Swahili)",
  CardBackgroundNameEnglish: "Card Background Name (English)",
  UploadEmoji: "Upload Emoji",
  Price: "Price",
  tournamentNameOne: "Tournament Name 1",
  tournamentDateTime: "Tournament Start Date & Time",
  totalNumberOfPlayers: "Total Number Of Players",
  totalplayers: "6",
  winningAmount: "Winning Amount",
  amountnumber: "$35",
  editTournamentDetail: "Edit Tournament Details",
  add: "Add",
  addTournamentDetail: "Add Tournament Details",
  date: "2024-02-07 18:18:00",
  addCoins: "Add Coins",
  delete: "Delete ",
  notifcationManagement: "Notification Management",
  addNotification: "Add Notification",
  titile: "Title",
  description: "Description",
  editNotification: "Edit Notification",
  update: "Update",
  create: "Create Tournament",
  createTheme: "Create Theme",
  createCards: "Create Card",
  createEmoji: "Create Emoji",
  createTournamentDetail: "Create Tournament Details",
  createThemeDetail: "Create Theme Details",
  createCardDetail: "Create Card Details",
  createEmojiDetail: "Create Emoji Details",
  winer: "Winner",
  createbtn: "Create",
  blockUserWarning: "Are you sure, you want to block this user?",
  unblockUserWarning: "Are you sure, you want to unblock this user?",
  blockWarning:  `Are you sure, you want to block this tournament?`,
  unblockWarning:`Are you sure, you want to unblock this tournament?`,
  itemNameRequired: "Please enter item name.",
  minItemNameValidation: "Item name should contain atleast 2 characters.",
  maxItemNameValidation:
    "Item name should not contain more than 15 characters.",
  costRequired: "Please enter cost.",
  minCostValidation: "Cost should should be grater than 0.",
  maxCostValidation: "Cost should not be greater than 100.",
  itemType: "Please select item type.",
  registerationAmount: "Registeration Amount",
  buttonupate: "Update",
  keysobj: {
    refferal: "Refferal",
    shop: "shop",
    inappPurchase: "In App Purchase of coins",
    tournamentRegister: "Tournament Register",
    adminAddCoins: "Admin Add Coins",
    randomMatchDeduction: "Random Match Deduction",
    friendMatch: "Friend Match",
    tournamentWin: "Tournament Win",
    tournamentLose:"Tournament Loose",
    randomMatchWin: "Random Match Win",
    signUpBonus:"Sign Up Bonus"
}
};
export default appconstant;
