import downlaod from "../images/user1.png";
import C3 from "../images/play/C3.png";
import C4 from "../images/play/C4.png";
import C5 from "../images/play/C5.png";
import C6 from "../images/play/C6.png";
import C7 from "../images/play/C7.png";
import CA from "../images/play/CA.png";
import CJ from "../images/play/CJ.png";
import CK from "../images/play/CK.png";
import CQ from "../images/play/CQ.png";

import D3 from "../images/play/D3.png";
import D4 from "../images/play/D4.png";
import D5 from "../images/play/D5.png";
import D6 from "../images/play/D6.png";
import D7 from "../images/play/D7.png";
import DA from "../images/play/DA.png";
import DJ from "../images/play/DJ.png";
import DK from "../images/play/DK.png";
import DQ from "../images/play/DQ.png";

import S3 from "../images/play/S3.png";
import S4 from "../images/play/S4.png";
import S5 from "../images/play/S5.png";
import S6 from "../images/play/S6.png";
import S7 from "../images/play/S7.png";
import SA from "../images/play/SA.png";
import SJ from "../images/play/SJ.png";
import SK from "../images/play/SK.png";
import SQ from "../images/play/SQ.png";

import H3 from "../images/play/H3.png";
import H4 from "../images/play/H4.png";
import H5 from "../images/play/H5.png";
import H6 from "../images/play/H6.png";
import H7 from "../images/play/H7.png";
import HA from "../images/play/HA.png";
import HJ from "../images/play/HJ.png";
import HK from "../images/play/HK.png";
import HQ from "../images/play/HQ.png";

import Diamond from "../images/play/diamond.png"
import Club from "../images/play/Club.png"
import Spade from "../images/play/Spades.png"
import Heart from "../images/play/Hearts.png"



const images = {
  C3,
  C4,
  C5,
  C6,
  C7,
  CA,
  CJ,
  CK,
  CQ,

  D3,
  D4,
  D5,
  D6,
  D7,
  DA,
  DJ,
  DK,
  DQ,

  S3,
  S4,
  S5,
  S6,
  S7,
  SA,
  SJ,
  SK,
  SQ,

  H3,
  H4,
  H5,
  H6,
  H7,
  HA,
  HJ,
  HK,
  HQ,
  Diamond,
  Heart,
  Spade,
  Club,

  login: require("../images/logo-login.svg").default,
  download: downlaod,
  down: require("../images/first.png").default,
  login2: require("../images/slider.png").default,
  user7: require("../images/user4.png").default,
  usermessage: require("../images/man.png").default,
  pluss: require("../images/plus.png").default,
  userWeek: require("../images/user-week.png").default,
  usermonth: require("../images/user-month.png").default,
  userquarter: require("../images/user-quarter.png").default,
  thisyear: require("../images/this-year.png").default,
  totalGame: require("../images/total-game.png").default,
  todayGame: require("../images/today-game.png").default,
  gameWeek: require("../images/game-week.png").default,
  gamemonth: require("../images/game-month.png").default,
  gamequarter: require("../images/game-quarter.png").default,
  gameyear: require("../images/game-year.png").default,
  eye: require("../images/eye.svg").default,
  hidden: require("../images/hidden.svg").default,
  dummyUser: require("../images/dummy_user.png").default,
  avatar1: require("../images/avtar/Basketball.png").default,
  avatar2: require("../images/avtar/Elephant.png").default,
  avatar3: require("../images/avtar/Basketball.png").default,
  avatar4: require("../images/avtar/Fox.png").default,
  avatar5: require("../images/avtar/King.png").default,
  avatar6: require("../images/avtar/Pumba.png").default,
  avatar7: require("../images/avtar/Turtle.png").default,
  Default: require("../images/avtar/UserImage.png").default,
};

export default images;
