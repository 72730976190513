import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from 'antd';
import appconstant from "../../themes/appconstant";
import { notificationList, notificationDelete } from '../../redux/Action/Auth'
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";


const NotificationManagement = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    let [search, setSearch] = useState('')
    const [currentPage, setCurrentPage] = useState(1)

    const disableModel = (record) => {

        Modal.confirm({
            title: "Delete",
            content: `Are you sure, you want to delete this notification?`,
            okText: 'Yes',
            centered: true,
            cancelText: 'No',
            onOk() {

                dispatch(notificationDelete({ payload: { id: record }, list: { offset: (currentPage - 1) * 10, limit: 10, search } }))
            },
            onCancel() {

            },
            maskClosable: true,
            className: "new-button"
        });
    };

    useEffect(() => {
        dispatch(notificationList())
        return () => Modal.destroyAll()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const data = useSelector((state) => state.notificationReducer.list);
    const pagination = useSelector((state) => state.notificationReducer.pagination);
    const loading = useSelector((state) => state.notificationReducer.loading);

    // handle pagination and sorting
    const handleTableChange = (paginationChange, x, sortChange) => {

        setCurrentPage(paginationChange.current)
        let offset = (paginationChange.current - 1) * 10
        let limit = 10
        let sort = sortChange?.columnKey || null
        let order = sortChange?.order === "ascend" ? 1 : sortChange?.order === "descend" ? -1 : null

        dispatch(notificationList({ search, offset, limit, order, sortBy: sort }))
    }

    const dataSource = data.map((e, index) => {
        return {
            _id: e._id,
            title: e.title,
            description: e.description,
            key: index
        }
    })

    const columns = [{
        title: 'Sr. No.',
        dataIndex: 'srno',
        key: 'srno',
        sorter: false,
        render: (value, item, index) => ((currentPage - 1) * 10) + (index + 1)
    },
    {
        title: 'Title  ',
        dataIndex: 'title',
        key: 'title',
        sorter: true,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        sorter: true,
    },
    {
        title: 'Actions',
        dataIndex: 'Posted',
        key: 'Posted',
        render: (value, item, index) => {
            return (

                <div>
                    {/* <Link to="/employeeDetails">
                            <button type="submit" >{appconstant.view}</button>
                        </Link> */}
                    {/* <Link to="/editNotification"> */}
                    <button className="edit-btn" type="submit" onClick={() => navigate('/editNotification', { state: { data: item } })} >{appconstant.edit}</button>
                    {/* </Link> */}
                    <button className="block-btn" type="submit" onClick={() => disableModel(item._id)} >{appconstant.delete}</button>

                    {/* <button className="pass-gen" type="submit" onClick={disableModel2} >{appconstant.respass}</button> */}
                </div>
            )
        }
    }];

    useEffect(() => {
        document.title = 'Albastini';
    }, []);

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item>
                    {appconstant.notifcationManagement}
                </Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.notifcationManagement}</h6>
                        <input
                            type="text"
                            className="iocn-search  search-bar"
                            placeholder="Search"
                            value={search}
                            onChange={(e) => {
                                const value = (e.target.value).trimStart()
                                setSearch(value)
                                setCurrentPage(1)
                                dispatch(notificationList({ search: value, offset: 0, limit: 10 }))
                            }}
                        />

                    </div>
                    <div >
                        <Link to="/addnotification"><button type="submit" className="button-list">{appconstant.addNotification}</button>
                        </Link>

                    </div>

                    <Table

                        dataSource={dataSource}
                        columns={columns}
                        showSorterTooltip={false}
                        pagination={{ total: pagination?.totalCount || 0, current: currentPage,showSizeChanger:false }}
                        onChange={handleTableChange}
                        loading={loading}

                    />
                </div>
            </div>

        </div>

    )
}
export default NotificationManagement

